import React from 'react';
import {BrowserRouter, Switch, Route, Redirect, withRouter} from 'react-router-dom'

import IdleTimer from 'react-idle-timer'
import {toastr} from './_helpers/Loader';

import uuidv1 from 'uuid';

import {Context, MyContext} from './components/MyContext'

import Login from './components/account/Login';
import Settings from './components/account/Settings';
import UpdatePassword from './components/account/UpdatePassword';
import ForgetPassword from './components/account/ForgotPassword'
import Investments from './components/investments/Investments';
import InvestmentDetail from './components/investments/InvestmentDetail';
import ResetPassword from './components/account/ResetPassword'


import {authHeader} from './_helpers/auth-header'
import {userService} from "./_services";


const PrivateRoute = ({component: Component, ...rest}) => (
    <Route  {...rest} render={(props) => (
        authHeader()
            ? <Component {...props} />
            : <Redirect to='/login'/>
    )}/>
);

class App extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.idleTimer = null;
        this.onIdle = this._onIdle.bind(this);
    }

    _onIdle(e) {
        toastr.info("App is logging off due to inactivity. You can login again.");
        setTimeout(() => {
            userService.logout();
            window.location.reload();
        }, 6000);
    }


    render() {
        return (
            <MyContext>
                <IdleTimer
                    ref={ref => {
                        this.idleTimer = ref
                    }}
                    element={document}
                    onIdle={this.onIdle}
                    debounce={250}
                    timeout={900000}/>
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/login" component={Login}/>
                        <Route exact path="/forget-password" component={ForgetPassword}/>
                        <Route exact path="/access/password-reset/confirm/:user_id/:token" component={ResetPassword}/>
                        <PrivateRoute exact path="/settings" component={Settings}/>
                        <PrivateRoute exact path="/settings/update-password" component={UpdatePassword}/>
                        <PrivateRoute exact path="/investment/portfolio/:id" component={InvestmentDetail}/>
                        <PrivateRoute exact path="*" component={Investments}/>
                    </Switch>
                </BrowserRouter>
            </MyContext>
        )
    }
}

export default withRouter(App);
