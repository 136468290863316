import React, {Component} from 'react';
import {authReducer} from '../reducers/authReducers'
import API from "../api";
import {hideLoader, toastr} from "../_helpers/Loader";

const Context = React.createContext({});

class MyContext extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            clubs: JSON.parse(localStorage.getItem('clubs')),
            user_state: (localStorage.getItem('userState')) ? localStorage.getItem('userState') : 'Personal',
            club_id: JSON.parse(localStorage.getItem('clubID'))
        };

        this.updateUser = this.updateUser.bind(this);
        this.changeUserState = this.changeUserState.bind(this);
        this.resetState = this.resetState.bind(this);
        this.clearState = this.clearState.bind(this)
    }

    async updateClubs() {
        try {
            let res = await API.get('clubs/');
            localStorage.setItem('clubs', JSON.stringify(res.data));
            this.setState({clubs: res.data})
        } catch (e) {
            toastr.error("Something Went Wrong");
            console.log(e.response)
        }
    }

    async changeUserState(club_id) {
        if (club_id === 'personal') {
            localStorage.setItem('userState', 'Personal');
            localStorage.setItem('ClubID', '');
            this.setState({user_state: 'Personal'});
            this.setState({club_id: false});
        } else {
            this.state.clubs.map(item => {
                (club_id == item.id) && localStorage.setItem('userState', item.name);
                (club_id == item.id) && localStorage.setItem('clubID', item.id);
                (club_id == item.id) && this.setState({user_state: item.name});
                (club_id == item.id) && this.setState({club_id: item.id});
            });
        }
    }

    toggleUserState() {
        this.setState({user_state: (this.state.user_state === 'personal') ? 'club' : 'personal'})
    }

    async updateUser() {
        try {
            let res = await API.get('users/');
            await localStorage.setItem('user', JSON.stringify(res.data[0]));
            this.setState({user: JSON.parse(localStorage.getItem('user'))});
        } catch (e) {
            console.log(e.response);
        }
    }

    async resetState() {
        this.setState({
            user: JSON.parse(localStorage.getItem('user')),
            clubs: JSON.parse(localStorage.getItem('clubs')),
            user_state: (localStorage.getItem('userState')) ? localStorage.getItem('userState') : 'Personal',
            club_id: JSON.parse(localStorage.getItem('clubID'))
        })
    }

    async clearState() {
        this.setState({
            user: {},
            clubs: [],
            user_state: null,
            club_id: null
        })
    }

    render() {
        return (
            <Context.Provider value={{
                user: this.state.user,
                updateUser: this.updateUser,
                clubs: this.state.clubs,
                club_id: this.state.club_id,
                updateUserState: this.changeUserState,
                userState: this.state.user_state,
                updateClubs: this.updateClubs,
                main_club: this.state.main_club,
                clearState: this.clearState,
                resetState: this.resetState
            }}>
                {this.props.children}
            </Context.Provider>
        );
    }
}

export {Context, MyContext}
