import React, {Component} from 'react';
import {Link} from "react-router-dom";
import API from '../../api'
import Layout from "../Layout";
import {userContext} from '../../_helpers/userContext'
import {showLoader, hideLoader, toastr} from "../../_helpers/Loader";
import NumberFormat from 'react-number-format';
import CONGRATS from "../../assets/img/congrats.svg";
import {userService} from "../../_services";
import ReactTooltip from "react-tooltip";
import LOGO from "../../assets2/img/logo-white.png";


const user = userContext;

class UpdatePassword extends Component {
    constructor(props) {
        super(props);

        userService.logout();

        this.state = {
            password1: '',
            password2: '',
            errors: []
        };

        this.updateDetails = this.updateDetails.bind(this);
    }

    async updateDetails(event) {
        event.preventDefault();
        showLoader();
        let postData = {
            new_password1: this.state.password1,
            new_password2: this.state.password2,
            uid: this.props.match.params.user_id,
            token: this.props.match.params.token
        };
        try {
            let res = await API.post('rest-auth/password/reset/confirm/', postData);
            toastr.success("Updated Successfully");
            setTimeout(() => {
                this.props.history.push("/login");
            }, 2000);
            hideLoader();
        } catch (err) {
            toastr.error("Something went wrong");
            hideLoader();
            let err_list = [];
            for (let key in err.response.data) {
                err.response.data[key].map(item => {
                    err_list.push(item);
                    setTimeout(() => {
                        toastr.error(item);
                    }, 2000);
                })
            }
            this.setState({errors: err_list});
            console.log(err.response.data);
        }
    }

    render() {
        return (
            <div id="app">
                <section className="section section-2">
                    <div className="row">
                        <div className="single-page single-pageimage construction-bg cover-image">
                            <div className="row">
                                <div className="col-lg-6 login-image">
                                    <div className="log-wrapper text-center img-container">
                                        <img src={LOGO} width="50%"
                                             className="mb-2 mt-4 mt-lg-0 mb-5" alt="logo"/>
                                        <div className="overlay text-center">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 ">
                                    <div className="wrapper wrapper2">
                                        <form onSubmit={this.updateDetails} className="card-body form-body"
                                              tabIndex="500">
                                            <h3 className="text-dark">Password Reset</h3>
                                            <small>
                                                <ul style={{listStyle: 'none'}} className="list-group text-left">
                                                    <li className="">Your password can't be too similar to your other
                                                        personal information such as first name, last name and email address.
                                                    </li>
                                                    <li className="">Your password must contain at least 8 characters.
                                                    </li>
                                                    <li className="">Your password can't be a commonly used password.
                                                    </li>
                                                    <li className="">Your password can't be entirely numeric.</li>
                                                </ul>
                                            </small>
                                            <br/>
                                            <div className="passwd text-left">
                                                <input required value={this.state.password}
                                                       onChange={event => this.setState({password1: event.target.value})}
                                                       type="password" name="password"/>
                                                <label>Enter New Password</label>
                                            </div>
                                            <div className="passwd text-left">
                                                <input required value={this.state.password}
                                                       onChange={event => this.setState({password2: event.target.value})}
                                                       type="password" name="password"/>
                                                <label>Reenter New Password</label>
                                            </div>
                                            {(this.state.password2.length > 0 && this.state.password1 !== this.state.password2) ? (
                                                <p className="text-danger">Password combination incorrect</p>
                                            ) : (this.state.password2 === this.state.password1 && this.state.password1.length > 0) ? (
                                                <p className="text-orange">Password combination correct</p>
                                            ) : ('')}
                                            <ul>
                                            {this.state.errors.map(item=>(
                                                <li className="text-left text-danger">{item}</li>
                                            ))}
                                            </ul>
                                            <div className="submit">
                                                <input value='Reset' type="submit" className="btn btn-green btn-block"/>
                                            </div>
                                            <p className="text-dark mb-0"><Link to="/login" className="ml-1">Login to
                                                your account</Link></p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default UpdatePassword;
