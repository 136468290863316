export function authHeader() {
    // return authorization header with basic auth credentials
    let auth = JSON.parse(localStorage.getItem('auth'));

    if (auth) {
        return { 'Authorization': 'JWT ' + auth.data.token };
    } else {
        return false;
    }
}


