import React, {Component} from 'react';
import {Link} from "react-router-dom";
import API from '../../api';
import LOGO from "../../assets2/img/logo-white.png";
import {toastr} from "../../_helpers/Loader";
import {hideLoader, showLoader} from "../../_helpers/Loader";

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            info: '',
            error: '',
        };

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    async handleSubmit(event){
        event.preventDefault();
        let postData = {email: this.state.email};
        showLoader();
        try{
            await API.post('rest-auth/password/reset/', postData);
            toastr.success("A Password Reset Link Has Been Sent to Your email");
            hideLoader();
            setTimeout(()=>{
                this.props.history.push("/login")
            }, 2000)
        }catch (e) {
            toastr.error("Something went wrong!");
            hideLoader();
            this.setState({error:e.response.data});
        }
    }

    render() {
        return (
            <div id="app" user_state={'personal'}>
                <section className="section section-2">
                    <div className="row">
                        <div className="single-page single-pageimage construction-bg cover-image">
                            <div className="row">
                                <div className="col-lg-6 login-image">
                                    <div className="log-wrapper text-center img-container">
                                        <img src={LOGO} width="50%"
                                             className="mb-2 mt-4 mt-lg-0 mb-5" alt="logo"/>
                                        <div className="overlay text-center">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="wrapper wrapper2">
                                        <form onSubmit={this.handleSubmit} className="card-body form-body"
                                              tabIndex="500">
                                            <h3>Forget Password</h3>
                                            <p className="text-main">Enter your email address below and we will send you instructions on how
                                                to reset your password.</p>
                                            <div className="mail text-left">
                                                <input required value={this.state.email}
                                                       onChange={event => this.setState({email: event.target.value})}
                                                       type="email" name="mail"/>
                                                <label>Email Address</label>
                                            </div>
                                            {(this.state.error) ? (
                                                <p className="text-left text-danger">{this.state.error.email[0]}</p>
                                            ) : ''}
                                            <div className="submit">
                                                <input value="Submit" type="submit" className="btn btn-green btn-block"/>
                                            </div>
                                            <Link to="/login"
                                                  className=" ml-1">Login to Your Account</Link>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default ForgotPassword;
