import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import API from '../../api';

import Layout from '../Layout';
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import {Context} from "../MyContext";
import {hideLoader, showLoader} from "../../_helpers/Loader";

class InvestmentDetail extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            investor_order: [],
            individuals: [],
            clubs: [],
            total_amount: null,
            total_purchased_units: null,
            total_expected: null
        }

    }

    async componentDidMount() {
        showLoader()
        await this.getIndividualOrders();
        hideLoader()
    }

    getIndividualOrders = async () => {
        let postData = {
            investment_id: this.props.match.params.id
        };
        try {
            let res = await API.post('my_investments/partner_investors/', postData);
            let total_amount = 0, total_expected = 0, total_purchased_units = 0;
            res.data.map(item => {
                total_amount = total_amount + parseFloat(item.amount);
                total_expected = total_expected + parseFloat(item.expected_return);
                total_purchased_units = total_purchased_units + parseFloat(item.purchased_units)
            });
            console.log(total_amount);
            this.setState({
                individuals: res.data,
                investor_order: res.data,
                total_amount: total_amount,
                total_expected: total_expected,
                total_purchased_units: total_purchased_units
            });
        } catch (e) {
            console.log(e)
        }
    };

    getClubOrders = async () => {
        try {
            let res = await API.get('club_investments/paid_investment/');
            this.setState({
                clubs: res.data
            })
        } catch (e) {
            console.log(e)
        }
    };

    getInvestments = async () => {
        try {
            let res = await API.get(`investments/${this.props.match.params.id}/`);
            this.setState({
                investments: res.data
            });
            console.log(res)
        } catch (e) {


        }
    };

    filterInvestor = async (event) => {
        showLoader();
        if (event.target.value === 'individual') {
            this.setState({investor_order:this.state.individuals})
        } else {
            this.setState({investor_order:this.state.clubs})
        }
        hideLoader();
    };

    render() {
        return (
            <Layout active_menu={'investment'} context={this.context}>
                <div className="breadcrumb pl-0">
                    <div className="col-5 pl-0">
                        <Link to="/investments"><span className="fa fa-chevron-left"/> Investments</Link>
                    </div>
                </div>

                <div className="row" id="investment">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-lg-6 col-sm-12">
                                        <h4 className="float-left">My Investments</h4>
                                    </div>
                                    <div className="col-lg-6 col-sm-12 text-xl-right text-lg-right text-md-left">
                                        <select onChange={event => this.filterInvestor(event)} name="" id=""
                                                className="col-md-6 form-control float-right">
                                            <option value="individual">Individuals</option>
                                            <option value="club">Clubs</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row boxes">
                                    <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                        <div className="card bg-green text-white">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-6" style={{paddingLeft: 0}}>
                                                        <i className="fa fa-bar-chart"/>
                                                    </div>
                                                    <div className="col-md-6 float-right text-right"
                                                         style={{paddingRight: 0}}>
                                                        <i data-tip="Total Amount Invested "
                                                           className="fa fa-info-circle text-right float-right"/>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="mt-3 text-center">
                                                            <span
                                                                className="text-white"><strong>Number of transaction</strong></span>
                                                            <h2 className="text-white mb-0">{this.state.investor_order.length}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                        <div className="card bg-green text-white">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-6" style={{paddingLeft: 0}}>
                                                        <i className="fa fa-coins"/>
                                                    </div>
                                                    <div className="col-md-6 float-right text-right"
                                                         style={{paddingRight: 0}}>
                                                        <i data-tip="Expected return on investment"
                                                           className="fa fa-info-circle text-right"/>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-white"><strong>Total Invested Funds</strong></span>
                                                            <h2 className="text-white mb-0">₦{parseFloat(this.state.total_amount).toLocaleString()}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                        <div className="card bg-green text-white">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-6" style={{paddingLeft: 0}}>
                                                        <i className="fa fa-reply-all"/>
                                                    </div>
                                                    <div className="col-md-6 float-right text-right"
                                                         style={{paddingRight: 0}}>
                                                        <i data-tip="Total amount you will receive from your investments"
                                                           className="fa fa-info-circle text-right"/>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-white"><strong>Total Expected Payouts</strong></span>
                                                            <h2 className="text-white mb-0">₦{parseFloat(this.state.total_expected).toLocaleString()}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                        <div className="card bg-green text-white">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-6" style={{paddingLeft: 0}}>
                                                        <i className="fa fa-signal"/>
                                                    </div>
                                                    <div className="col-md-6 float-right text-right"
                                                         style={{paddingRight: 0}}>
                                                        <i data-tip="Percentage Interest gained from  your investments"
                                                           className="fa fa-info-circle text-right"/>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-white"><strong>Total Units Sold</strong></span>
                                                            <h2 className="text-white mb-0">{parseFloat(this.state.total_purchased_units)}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-striped mb-0 text-nowrap">
                                        <tbody>
                                        <tr>
                                            <th>Investor</th>
                                            <th>Amount Invested</th>
                                            <th>Unit</th>
                                            <th>Expected Return</th>
                                            <th>Status</th>
                                            <th>Investment Date</th>
                                            <th>Maturity Date</th>
                                        </tr>
                                        {this.state.investor_order.map(item => (
                                            <tr role="row" key={item.id}>
                                                <td className="sorting_1"
                                                    style={{textTransform: 'capitalize'}}>{item.investor.first_name.toLowerCase()} {item.investor.last_name.toLowerCase()}</td>
                                                <td>&#8358;{parseFloat(item.amount).toLocaleString()}</td>
                                                <td>{parseFloat(item.purchased_units).toLocaleString()}</td>
                                                <td>&#8358;{parseFloat(item.expected_return).toLocaleString()}</td>
                                                <td>{(item.status === 'paid') ? (
                                                        <span className="badge bg-green m-b-5">Paid</span>) :
                                                    (<span className="badge bg-orange m-b-5">Pending</span>)}</td>
                                                <td><Moment format="MMM D, Y">{item.created_at}</Moment></td>
                                                <td><Moment format="MMM D, Y">{item.maturity_date}</Moment></td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default withRouter(InvestmentDetail);
