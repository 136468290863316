import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import Moment from 'react-moment';

class Layout extends React.Component {
    constructor(props) {
        super(props);

    }

    componentWillMount() {
        console.log(this.props.context, this.props)
    }

    render() {
        const date = new Date();

        return (
            <div>
                <div id="app">
                    <div className="main-wrapper">
                        <nav className="navbar navbar-expand-lg main-navbar">
                            <a className="header-brand">
                                <img src="/assets/img/logo.png" className="header-brand-img"/>
                            </a>

                            <div className="form-inline mr-auto"/>

                            <a id="collapse-menu"
                                href="#"
                                data-toggle="sidebar"
                                className="nav-link nav-link-lg">
                                <i className="ion ion-navicon-round text-logo"/>
                            </a>

                            <ul className="navbar-nav navbar-right">
                                <li className="mr-5 mytime">
                                    <div className="nav-link notification-toggle nav-link-lg">
                                        <i className="fa fa-clock text-white"/>
                                        <strong>
                                            <Moment className="text-default" format="MMM D, Y">
                                                {date}
                                            </Moment>
                                        </strong>
                                    </div>
                                </li>
                                <li className="ml-5 mylogout">
                                    <Link
                                        to="/login"
                                        className="nav-link nav-link-lg full-screen-link"
                                    >
                                        <i
                                            className="fa fa-sign-out-alt text-default"
                                            id="fullscreen-button"
                                        />
                                        <strong className="text-primary text-default"> Logout</strong>
                                    </Link>
                                </li>
                            </ul>
                        </nav>

                        <aside className="app-sidebar side">
                            <div className="app-sidebar__user">
                                <div className="dropdown">
                                    <div className="nav-link pl-2 pr-2 leading-none d-flex">
                                        <a >
                                            <img
                                                alt="profile image"
                                                src={
                                                    this.props.context.user.profile_photo
                                                      ? this.props.context.user.profile_photo
                                                      : 'https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png'
                                                }
                                                className=" avatar-md rounded-circle"
                                            />
                                        </a>
                                        <span className="ml-2 d-lg-block" style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                    <span className=" app-sidebar__user-name mt-5">
                      {this.props.context.user.first_name}{' '}
                      {this.props.context.user.last_name}
                    </span>
                    <br/>
                    <div className="dropdown d-inline show">
                      <div
                          className="dropdown-menu sidebar-dropdown"
                          x-placement="bottom-start"
                          style={{
                              position: 'absolute',
                              transform: 'translate3d(0px, 30px, 0px)',
                              top: '0px',
                              left: '0px',
                              willChange: 'transform'
                          }}
                      />
                    </div>
                  </span>
                                    </div>
                                </div>
                            </div>

                            <ul className="side-menu">
                                <li>
                                    <Link to="/"
                                        className={`side-menu__item toggle_menu ${
                                            this.props.active_menu === 'investment' ? 'active' : ''
                                            }`}>
                                        <i className="side-menu__icon fa fa-eye"/>
                                        <span className="side-menu__label">Investments</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/settings"
                                        className={`side-menu__item toggle_menu ${
                                            this.props.active_menu === 'setting' ? 'active' : ''
                                            }`}
                                    >
                                        <i className="side-menu__icon fa fa-users"/>
                                        <span className="side-menu__label">Settings</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/login" className="side-menu__item">
                                        <i className="side-menu__icon fa fa-sign-out-alt"/>
                                        <span className="side-menu__label">Logout</span>
                                    </Link>
                                </li>
                            </ul>
                        </aside>

                        <div className="app-content">{this.props.children}</div>

                        <footer className="main-footer">
                            <div className="text-center">Copyright &copy;Twelvevest 2020.</div>
                        </footer>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Layout);
