import {authHeader} from '../_helpers';

import API from '../api'


export const userService = {
    login,
    logout,
    getAll
};

async function login(email, password) {

}

async function logout() {
    // remove user from local storage to log user out
    try {
        await API.post('rest-auth/logout/');
        localStorage.clear();
    } catch (e) {
        console.log(e.response)
    }
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`/users`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
