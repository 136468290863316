import React from 'react';
import {Link} from "react-router-dom";
import {withRouter} from 'react-router-dom';
import API from '../../api';

import Layout from '../Layout';
import {Context} from "../MyContext";
import {hideLoader, showLoader} from "../../_helpers/Loader";

class Investments extends React.Component {
    static contextType = Context;
    constructor(props) {
        super(props);

        this.state = {
            investments: [],
        }

    }

    async componentDidMount() {
        showLoader()
        await this.getInvestments();
        hideLoader()
    }

    getInvestments = async () => {
        try {
            let res = await API.get('investments/partner_investments/');
            this.setState({
                investments: res.data
            });
        } catch (e) {
            hideLoader()
        }
    };

    render() {
        return (
            <Layout active_menu={'investment'} context={this.context}>
                <div className="breadcrumb pl-0">
                    <div className="col-5 pl-0">
                        <h5>Investments</h5>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header bg-green">
                                <h4>Investments</h4>
                            </div>
                            <div className="card-body">
                                <div id="example_wrapper"
                                     className="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <table
                                        className="table table-striped table-bordered border-t0 text-nowrap w-100 dataTable no-footer"
                                        role="grid" aria-describedby="example_info">
                                        <thead>
                                        <tr role="row">
                                            <th className="wd-15p sorting_asc" tabIndex="0"
                                                aria-controls="example" rowSpan="1" colSpan="1"
                                                aria-sort="ascending"
                                                aria-label="First name: activate to sort column descending"
                                                style={{width: "113px"}}>Title
                                            </th>
                                            <th className="wd-15p sorting" tabIndex="0"
                                                aria-controls="example"
                                                rowSpan="1" colSpan="1"
                                                aria-label="Last name: activate to sort column ascending"
                                                style={{width: "113px"}}>Total Amount Raised
                                            </th>
                                            <th className="wd-15p sorting" tabIndex="0"
                                                aria-controls="example"
                                                rowSpan="1" colSpan="1"
                                                aria-label="Last name: activate to sort column ascending"
                                                style={{width: "113px"}}>Units
                                            </th>
                                            <th className="wd-15p sorting" tabIndex="0"
                                                aria-controls="example"
                                                rowSpan="1" colSpan="1"
                                                aria-label="Last name: activate to sort column ascending"
                                                style={{width: "113px"}}>Purchased Units
                                            </th>
                                            <th className="wd-15p sorting" tabIndex="0"
                                                aria-controls="example"
                                                rowSpan="1" colSpan="1"
                                                aria-label="Last name: activate to sort column ascending"
                                                style={{width: "113px"}}>ROI
                                            </th>
                                            <th className="wd-15p sorting" tabIndex="0"
                                                aria-controls="example"
                                                rowSpan="1" colSpan="1"
                                                aria-label="Last name: activate to sort column ascending"
                                                style={{width: "113px"}}>Action
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.investments.map(item => (
                                            <tr role="row" key={item.id}>
                                                <td className="sorting_1">{item.title}</td>
                                                <td>&#8358;{parseFloat(item.amount_raised).toLocaleString()}</td>
                                                <td>{parseFloat(item.units)}</td>
                                                <td>{(item.total_units_sold) ? item.total_units_sold.toLocaleString() : 0}</td>
                                                <td>{item.inv_roi.toLocaleString()}%</td>
                                                <td>
                                                    <Link to={`/investment/portfolio/${item.id}`} className="btn btn-orange btn-sm mt-1"><span
                                                        className="fa fa-list"/></Link>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default withRouter(Investments);
